// To add more languages, append to the AppLanguageCode type in src/types/language.ts
// e.g.: export type AppLanguageCode = 'en' | 'es' | 'fr' | 'de';
export type AppLanguageCode = 'en' | 'es';

export type AppLanguage = {
  label: string;
  code: AppLanguageCode;
};

export type AppLanguages = {
  [key in AppLanguageCode]: AppLanguage;
};

export const DEFAULT_LANGUAGE = 'en';

export const languages: AppLanguages = {
  en: { label: 'English', code: 'en' },
  es: { label: 'Español', code: 'es' },
};
